<template>
  <div>
    <PageHeader />
    <b-button-group class="mb-2">
      <b-button
        v-for="item in menu"
        :key="item.name"
        :variant="selectedGameType === item.name ? 'dark' : 'light'"
        @click="selectedGameType = item.name"
      >
        {{ item.label }}
      </b-button>
    </b-button-group>

    <b-overlay :show="isFetchingLottoGame">
      <b-card>
        <h6>
          {{ `${$t('fields.result')} (${total})` }}
        </h6>
        <LimitSelect v-model="selectedLimit" class="mb-3" />

        <b-table :fields="fields" :items="filteredList" responsive show-empty>
          <template #cell(lottoGameRounds)="data">
            {{  getValueOfGameRound(data.item.lottoGameRounds, 'gameDate') | date }}
          </template>

          <template #cell(lottoStatus)="data">
            <b-badge
              :variant="
                displayLottoGameRoundStatusColor(
                  getValueOfGameRound(data.item.lottoGameRounds, 'status'),
                  getValueOfGameRound(data.item.lottoGameRounds, 'resultDatetime')
                )
              "
            >
              {{
                displayLottoGameRoundStatusLabel(
                  getValueOfGameRound(data.item.lottoGameRounds, 'status'),
                  getValueOfGameRound(data.item.lottoGameRounds, 'resultDatetime')
                )
              }}
            </b-badge>
          </template>

          <template #cell(incomePercentage)="data">
            <template v-if="data.item.lottoGameYeekeeConfig">
              {{ +data.item.lottoGameYeekeeConfig.incomePercentage }}%
            </template>
            <template v-else>
              0%
            </template>
          </template>

          <template #cell(fixResult)="data">
            <template v-if="data.item.lottoGameYeekeeConfig && (data.item.lottoGameYeekeeConfig.fixThreeResult || data.item.lottoGameYeekeeConfig.fixTwoResult)">
              <b-badge v-if="data.item.lottoGameYeekeeConfig.fixThreeResult" class="mx-1" variant="dark">
               {{ data.item.lottoGameYeekeeConfig.fixThreeResult }}
              </b-badge>
              <b-badge v-if="data.item.lottoGameYeekeeConfig.fixTwoResult" variant="dark">
               {{ data.item.lottoGameYeekeeConfig.fixTwoResult }}
              </b-badge>
            </template>
            <template v-else>
              -
            </template>
          </template>

          <template #cell(openDate)="data">
            {{ getValueOfGameRound(data.item.lottoGameRounds, 'startBetDatetime') | datetime }}
          </template>

          <template #cell(closeDate)="data">
            {{ getValueOfGameRound(data.item.lottoGameRounds, 'closeDatetime') | datetime }}
          </template>

          <template #cell(manageLotto)="data">
            <b-button-group size="sm">
              <template v-if="$allowPermission('owner:manage.lotto')">
                <template v-if="data.item.gameType !== LottoGameTypeEnum.YEEKEE">
                  <template v-if="onChangeStatus(data.item) === 'betting'">
                    <b-button v-if="data.item.lottoGameRounds.status === LottoGameRoundStatusEnum.OPENING" variant="danger" @click.prevent="toggleStatus(data.item.lottoGameRounds.id)">
                      หยุดรับแทง
                    </b-button>
                    <b-button v-else variant="info" @click.prevent="toggleStatus(data.item.lottoGameRounds.id)">
                      เปิดรับแทง
                    </b-button>
                  </template>

                  <b-button v-else-if="onChangeStatus(data.item) === 'result'" :to="`/lotto/${data.item.id}/results`" variant="success">
                    ออกผล
                  </b-button>

                  <b-button v-if="onChangeStatus(data.item) === 'done'" :to="`/lotto/${data.item.id}/round`" variant="primary">
                    เปิดรอบใหม่
                  </b-button>
                  <b-button v-else :to="`/lotto/${data.item.id}/round`" variant="secondary">
                    แก้ไขรอบ
                  </b-button>
                </template>
              </template>

              <b-dropdown dropright size="sm" variant="white">
                <template #button-content>
                  <i class="uil uil-cog"></i>
                </template>

                <b-dropdown-item v-if="$allowPermission('owner:manage.lotto')" :to="`/lotto/${data.item.id}/config?type=${selectedGameType}`">
                  <i class="uil uil-edit-alt mr-2"></i>
                  {{ $t('buttons.edit') }}
                </b-dropdown-item>
                <b-dropdown-item :to="`/lotto/user-bet?lottoGame=${data.item.id}&type=${selectedGameType}`">
                  <i class="uil uil-list-ul mr-2"></i>
                  {{ $t('buttons.view_lotto_user_bet') }}
                </b-dropdown-item>
                <b-dropdown-item :to="`/lotto/lotto-summary?lottoGame=${data.item.id}&type=${selectedGameType}`">
                  <i class="uil uil-dollar-sign mr-2"></i>
                  {{ $t('buttons.view_summary') }}
                </b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </template>

          <template #empty="">
            <p class="text-center text-muted">
              {{ $t('messages.nothing_here') }}
            </p>
          </template>
        </b-table>
        <PaginationInput
          :per-page="limit"
          :total="total"
          @update="(val) => (currentPage = val)"
        />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {displayLottoGameRoundStatusColor, displayLottoGameRoundStatusLabel} from './utils'
import {mapActions, mapGetters, mapState} from 'vuex'
import {LottoGameRoundStatusEnum, LottoGameTypeEnum} from '@src/constants/lotto';
import dayjs from 'dayjs';

export default {
  page: {
    title: 'รายการหวย',
  },
  data() {
    return {
      sortBy: 'name',
      currentPage: 1,
      selectedLimit: 20,
      selectedMaster: '',
      selectedAgent: '',
      selectedGameType: '',
      search: '',
      menu: [
        { name: LottoGameTypeEnum.THAI_GOVERNMENT, label: 'หวยรัฐบาล' },
        { name: LottoGameTypeEnum.THAI_BANK, label: 'หวยธนาคาร' },
        { name: LottoGameTypeEnum.THAI_STOCK, label: 'หวยหุ้นไทย' },
        { name: LottoGameTypeEnum.FOREIGN_STOCK, label: 'หวยหุ้นต่างประเทศ' },
        { name: LottoGameTypeEnum.FOREIGN_LOTTO, label: 'หวยต่างประเทศ' },
        { name: LottoGameTypeEnum.SET, label: 'หวยชุด' },
        { name: LottoGameTypeEnum.YEEKEE, label: 'หวยยี่กี' },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetchingLottoGame: (state) => state.lotto.isFetchingLottoGame,
    }),
    ...mapGetters(['isMaster', 'isOwner', 'isAgent', 'lottoGameList']),
    filteredList() {
      return this.lottoGameList.items
    },
    pagination() {
      return this.lottoGameList.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
    LottoGameTypeEnum() {
      return LottoGameTypeEnum
    },
    LottoGameRoundStatusEnum() {
      return LottoGameRoundStatusEnum
    },
    fields() {
      let fields = [
        {
          key: 'name',
          label: 'ชื่อ',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'lottoGameRounds',
          label: 'รอบ',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'lottoStatus',
          label: 'สถานะ',
          class: 'text-center',
          thStyle: {
            minWidth: '80px',
          },
        },
        {
          key: 'incomePercentage',
          label: 'ตั้งกิน',
          class: 'text-center',
          thStyle: {
            maxWidth: '80px',
          },
        },
        {
          key: 'fixResult',
          label: 'ล็อคผล',
          class: 'text-center',
          thStyle: {
            maxWidth: '80px',
          },
        },
        {
          key: 'openDate',
          label: 'เปิดรับแทง',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'closeDate',
          label: 'ปิดรับแทง',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'manageLotto',
          label: 'จัดการ',
          class: 'text-center',
          thStyle: {
            minWidth: '150px',
          },
        }
      ]

      if (this.selectedGameType !== LottoGameTypeEnum.YEEKEE) {
        fields.splice(3, 2)
      }

      return fields
    }
  },
  watch: {
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    selectedLimit(val) {
      if(val) {
        this.currentPage > 1 ? this.currentPage = 1 : this.fetchData()
      }
    },
    selectedGameType(val) {
      if(val) {
        if(val !== this.$route.query.type) {
          this.$router.replace({
            query: {
              type: val,
            },
          })
        }
        this.fetchData()
      }
    }
  },
  created() {
    this.selectedGameType = this.$route.query.type || LottoGameTypeEnum.THAI_GOVERNMENT
  },
  methods: {
    displayLottoGameRoundStatusColor,
    displayLottoGameRoundStatusLabel,
    ...mapActions(['fetchLottoGameByGameType', 'toggleStatusLottoGameRounds']),
    fetchData() {
      this.fetchLottoGameByGameType({
        limit: this.selectedLimit,
        page: this.currentPage,
        agentId: this.selectedAgent,
        masterId: this.selectedMaster,
        gameType: this.selectedGameType,
      })
    },
    displayButtonManageStatusColor(type, resultDate) {
      resultDate = new Date(resultDate)
      if ((type === LottoGameRoundStatusEnum.CLOSE || type === LottoGameRoundStatusEnum.OPENING) && new Date() >= resultDate) {
        return 'btn btn-primary btn-sm'
      }
      switch (type) {
        case LottoGameRoundStatusEnum.OPENING:
          return 'btn btn-danger btn-sm'
        case LottoGameRoundStatusEnum.CLOSE:
          return 'btn btn-info btn-sm'
        case LottoGameRoundStatusEnum.DONE:
        case LottoGameRoundStatusEnum.REFUND:
        default:
          return 'btn btn-success btn-sm'
      }
    },
    displayButtonManageStatusLabel(type, resultDate) {
      resultDate = new Date(resultDate)
      if ((type === LottoGameRoundStatusEnum.CLOSE || type === LottoGameRoundStatusEnum.OPENING) && new Date() >= resultDate) {
        return 'ออกผล'
      }
      switch (type) {
        case LottoGameRoundStatusEnum.OPENING:
          return 'หยุดรับ'
        case LottoGameRoundStatusEnum.CLOSE:
          return 'เปิดรับ'
        case LottoGameRoundStatusEnum.DONE:
        case LottoGameRoundStatusEnum.REFUND:
        default:
          return 'เปิดรอบใหม่'
      }
    },
    getValueOfGameRound(data, key) {
      return data !== null ? data[key] : ''
    },
    toggleStatus(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.toggleStatusLottoGameRounds({
              id,
              gameType: this.selectedGameType,
            })
          }
        })
    },
    onChangeStatus(data) {
      const { lottoGameRounds: gameRound } = data
      if (gameRound !== null) {
        const isOpeningStatus = gameRound.status === LottoGameRoundStatusEnum.CLOSE || gameRound.status === LottoGameRoundStatusEnum.OPENING
        const isEndedStatus = gameRound.status === LottoGameRoundStatusEnum.REFUND || gameRound.status === LottoGameRoundStatusEnum.DONE

        if (isOpeningStatus && dayjs().isAfter(gameRound.resultDatetime)) {
          // Status: เกมเปิดรับแทง หรือปิดรับแทงชั่วคราวอยู่
          // Action: ออกผล
          return 'result'
        } else if (isOpeningStatus) {
          // Status: เกมเปิดรับแทง หรือปิดรับแทงชั่วคราวอยู่
          // Action: เปลี่ยนสถานะ เปิด/ปิด รับแทง
          return 'betting'
        } else if (isEndedStatus) {
          // Status: รอบที่ปัจจุบัน จบหรือคืนโพยไปแล้ว
          // Action: เปิดรอบใหม่
          return 'done'
        }
      } else {
        // Status: ยังไม่เคยมีรอบมาก่อน
        // Action: เปิดรอบใหม่
        return 'done'
      }
    },
  },
}
</script>
